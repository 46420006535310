
<template>
  <module
    ref="module"
    id="stats"
    titleIcon="fa fa-area-chart"
    :title="pageName"
    :hideSearchButton="true"
    :use-share-link="true"
    apiUrl="ACCAPIURL_V4"
    :url="`/affiliate/${userIdUrl}`"
    @on-copy-link="copyLink"
    :share-link="this.shareLink"
    :tooltip-share-text="$t('sharelinktxt')"
    :header-share-text="$t('sharelinktxt')"
    :useCardTop="true"
  >
    <template slot="global-top">
      <div class="col-md-12">
        <div v-if="inProgress" class="text-center">
          <h2>{{ $t('loadingtxt') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>
        <div v-else class="row">
          <div class="card-body text-center">
            <h4 class="mt-0"> {{ $t('sharelinktxt') }} </h4>
            <p-button type="neutral" outline v-on:click="copyLink">
              <h5> <i class="fa fa-clone"/> {{ shareLink }} </h5>
            </p-button><br>
            <h5 class="mt-1"> {{ $t('affiliatePercentage') }}: {{ affiliatePer * 100 }}% </h5>
          </div>
        </div>
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column v-for="column in tableColumns"
                       v-if="column.prop === 'total_equity_follower'"
                       :key="column.label"
                       :min-width="column.minWidth"
                       :prop="column.prop"
                       :label="column.label"
                       :sortable="column.sortable"
                       :align="column.align">
        <template slot-scope="{row}">
          <span v-if="row.total_equity_follower == null">-</span>
          <span v-else>${{row.total_equity_follower.toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column v-else-if="column.prop === 'affiliate_per'"
                       :key="column.label"
                       :min-width="column.minWidth"
                       :prop="column.prop"
                       :label="column.label"
                       :sortable="column.sortable"
                       :align="column.align">
        <template slot-scope="{row}">
          <span>{{row.affiliate_per * 100}}%</span>
        </template>
      </el-table-column>
      <el-table-column v-else
                       :key="column.label"
                       :min-width="column.minWidth"
                       :prop="column.prop"
                       :label="column.label"
                       :sortable="column.sortable"
                       :align="column.align"/>
    </template>
  </module>
</template>

<script>

import Constants from "src/assets/constants";

const user_data = JSON.parse(localStorage.getItem('user-info'));
const rules = Constants.methods.getRules();

export default {
  name: "Stats",
  data() {
    let self = this;
    return {
      userid: null,
      ueml: null,
      userId: null,
      adminusr: null,
      shareLink: '',
      affiliatePer: 0,
      inProgress: true,
      tableColumns: {
        userid: {
          prop: 'userid',
          label: self.$t('userid'),
          minWidth: "310px",
          align: '',
          sortable: true,
        },
        full_name: {
          prop: 'full_name',
          label: self.$t('fullname'),
          minWidth: "310px",
          align: '',
          sortable: true,
        },
        startdate: {
          prop: 'reg_dt',
          label: self.$t('startdate'),
          minWidth: "130px",
          align: '',
          sortable: true,
        },
        followers_account_qtd: {
          prop: 'followers_account_qtd',
          label: self.$t('number_of_followers'),
          minWidth: "130px",
          align: '',
          sortable: false,
        },
        number_of_vps: {
          prop: 'vps_qtd',
          label: self.$t('number_of_vps'),
          minWidth: "130px",
          align: '',
          sortable: false,
        },
        total_equity_follower: {
          prop: 'total_equity_follower',
          label: self.$t('equity'),
          minWidth: "130px",
          align: 'center',
          sortable: true,
        },
      },
    }
  },
  computed: {
    userIdUrl() {
      let userid = rules.userid;
      if (rules.isAdmin) {
        userid = this.$route.params.userid;
      }
      return userid;
    },
    pageName() {
      if(this.adminusr) {
        return this.$t('sign_up_stats_txt') + " " + this.userid;
      } else {
        return this.$t('sign_up_stats_txt');
      }
    },
  },
  methods: {
    affLoaded(resp) {
      if (!resp.success || !resp.data) {
        this.$toast.warning(this.$t(resp.msg))
        return;
      }
      const wl = rules.isWhitelabelSession ? `/${rules.whitelabel}` : '';
      this.shareLink = rules.getBaseUrl() + wl + '/register/' + resp.data.affiliateID;
      this.affiliatePer = resp.data.affiliate_per;
      this.inProgress = false
    },
    async copyLink() {
      try {
        await navigator.clipboard.writeText(this.shareLink);
        this.$toast.success(this.$t('linkcopied'));
      } catch ($e) {
        this.$toast.error($e.error);
      }
    },
  },
  mounted() {
    this.ueml = user_data.email;
    if (this.$route.params.userid) {
      this.userid = "("+ this.$route.params.userid +")";
      this.ueml = this.$route.params.userid;
      if(user_data.roles.indexOf('admin') >= 0) {
        this.adminusr = true;
      }
    }
    this.$getAffl(this.ueml).then(this.affLoaded);
  }
}
</script>
